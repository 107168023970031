
$color-primary-shade: #bf9525e5 !important;
$color-secondary: #bf9525;
$color-teritary: #2f9b47;


html,
body,
#root {
  min-height: 100% !important;
  height: 100% !important;
}

*{
  font-size: 14px !important;
}

.custom-fill {
  min-height: 100%;
  height: 100%;
}

.custom-secondary-color{
  color: $color-secondary !important;
  //fill: $color-secondary !important;
}  

.brand-title{
  color: #7d6b3b !important;
  font-size: 18px !important;
}

.custom-cursor-pointer{
  cursor: pointer !important;
}

.custom-overflow-auto {
  overflow: auto !important;
}

.custom-icon-loader{
  width: 45px !important;
  height: 45px !important;
  top: -9px !important;
  left: -9px !important;
  color: $color-secondary !important;
}

.custom-color-white{
  color: #ffffff !important;
}


.custom-font-bold{
  font-weight: bold;
}


// font style

.custom-font-style-italic{
  font-style: italic !important;
}

.custom-error {
  color: #d32f2f;
  font-size: 12px !important;
  padding: 0 5px;
}


.custom-color-white{
  color: white !important;
}

.custom-color-yash{
  color: #ddd !important;
}


.custom-background-primary-shade{
  background: $color-primary-shade;
}

.custom-background-yash{
  background: #ddd !important;
}

.custom-color-red{
  color: #d32f2f !important;
}

.custom-background-red{
  background: #d32f2f !important;
}

.custom-error-color{
  color: #d32f2f !important;
}

.custom-success-color{
  color: #9fc03b !important;
}


.custom-theme-color{
  color: #7d6b3b !important;
}

.custom-currency-theme-color{
  color: #7d6b3b !important;
}

.custom-currency-credit-color{
  color: #d32f2f !important;
}

.custom-currency-received-color{
  color: #9fc03b !important;
}

.custom-currency-yet-to-collect-color{
  color: #f48024 !important;
}

.custom-currency-yet-to-return-color{
  color: #0a95ff !important;
}



// Position classes

.custom-position-relative{
  position: relative !important;
}

.custom-position-absolute{
  position: absolute !important;
}

.toolbar{
  background: #ffffff !important;
  box-shadow: 0px 0px 5px $color-secondary !important;
}

.dashboard-tile{
  min-width: 150px;
  min-height: 75px;
  border: 1px solid #000;
  border-radius: 8px;
  margin: 8px;
  background: white;
  color: black;
  font-weight: bold;
  opacity: 85%;
  box-shadow: 5px 5px 6px #888 !important
}

.custom-bg-image{
  // background-image: url("https://www.fao.org/uploads/pics/est_web_header_template_rice2.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: #9d5c4a57;
}

.custom-bg-white{
  background: #ffffff !important;
}

.custom-login-page-bg{
  background-image: url("https://www.fao.org/uploads/pics/est_web_header_template_rice2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  //background-color: #9d5c4a57;
}

.custom-login-page-card{
  background: white;
  border-radius: 8px;
  opacity: 85%;
  border: 1px solid green !important;
}

.custom-dashboard-tile{
  border: 0.1px solid #7d6b3b;
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 4px;
  border-radius: 8px;
  min-width: 130px !important;
  min-height: 60px !important;
  box-shadow: 0px 0px 4px #7d6b3b;
}

.custom-dashboard-tile-value{
  font-size: 18px !important;
}


.common-border-green{
  border: 1px solid green;
}

.common-border-bottom-green{
  border-bottom: 1px solid green;
}


// Card styles

.MuiCard-root{
  padding: 12px !important;
  overflow: visible !important;
}


//Paper styles
.custom-form-section{
  padding: 8px !important;
  margin: 4px !important;
}

.custom-form-section-style{
  background: #df824a7d !important;
  color: #000 !important;
}


.custom-hyper-link{
  text-decoration: underline !important;
  cursor: pointer;
}

.custom-hyper-link:hover {
  text-decoration: underline !important;
  cursor: pointer;
}


// Form styles

.custom-form-field{
  margin-right: 16px !important;
  margin-bottom: 16px !important;
}


label{
  font-weight: bold;
  padding-left: 8px;
}


.custom-text-decoration-underline{
  text-decoration: underline !important;
}


.custom-button{
  background: $color-teritary !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

// Menu Styles

.custom-menu-item{
  border-bottom: 1px solid $color-primary-shade;
}

.custom-menu-item-selected{
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  background: #9fc03b !important;
  color: white !important;
}

// Screen styles

.custom-screen-header{
  border-bottom: 2px solid $color-secondary !important;
}

.custom-statement-card{
  border: 2px solid #ddd;
}












// Over ridding MUI input styles

// .MuiInputBase-root{
//   background: white !important;
// }

.MuiOutlinedInput-root{
  padding: 4px !important;
}

.MuiOutlinedInput-input{
  padding: 4px 4px 4px 4px !important;
}

.MuiIconButton-root{
  margin-right: -4px !important;
}

.MuiSvgIcon-root{
  height: 24px !important;
  width: 24px !important;
}

// .MuiFormControl-root{
//   margin-bottom: 16px !important;
// }


// Table style over ride
.MuiTableCell-stickyHeader{
  font-weight: bold !important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{
  margin-bottom: 0 !important;
}

.MuiTablePagination-toolbar{
  padding-left:8px !important
}

.MuiTablePagination-select{
  margin-right: 16px !important;
}

.MuiTablePagination-actions{
  margin-left: 16px !important;
}

.MuiTablePagination-root{
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  background: $color-secondary !important;
  color: white !important;
  .MuiTablePagination-select{
    background: $color-secondary !important;
    color: white !important;
  }

  .MuiTablePagination-selectIcon {
    top: 2px !important;
    color: white !important;
  }
}

.MuiTablePagination-toolbar{
  min-height: 40px !important;
}

.MuiTableCell-root{
  min-width: 200px !important;
  max-width: 200px !important;
  padding:8px 8px !important;
}

th {
  background: $color-secondary !important;
  color:white !important;
}

.custom-theme-icon-color-primary{
  color: $color-secondary !important;
}


@for $i from 0 to 1500 {
  .custom-z-index-#{$i} {
    z-index: $i  !important;
  }

  .custom-font-weight-#{$i} {
    font-weight: $i  !important;
  }


  .custom-font-size-#{$i} {
    font-size: #{$i}#{"px"}  !important;
  }


  .custom-height-#{$i}{
    height: #{$i}#{"px"} !important;
  }

  .custom-width-#{$i}{
    width: #{$i}#{"px"} !important;
  }

  .custom-min-height-#{$i}{
    min-height: #{$i}#{"px"} !important;
  }

  .custom-max-height-#{$i}{
    max-height: #{$i}#{"px"} !important;
  }

  .custom-min-width-#{$i}{
    min-width: #{$i}#{"px"} !important;
  }

  .custom-max-width-#{$i}{
    max-width: #{$i}#{"px"} !important;
  }

  .custom-height-percentage-#{$i}{
    height: #{$i}#{"%"} !important;
  }

  .custom-width-percentage-#{$i}{
    width: #{$i}#{"%"} !important;
  }

  .custom-margin-#{$i}{
    margin: #{$i}#{"px"} !important;
  }

  .custom-margin-top-#{$i}{
    margin-top: #{$i}#{"px"} !important;
  }

  .custom-margin-right-#{$i}{
    margin-right: #{$i}#{"px"} !important;
  }

  .custom-margin-bottom-#{$i}{
    margin-bottom: #{$i}#{"px"} !important;
  }

  .custom-margin-left-#{$i}{
    margin-left: #{$i}#{"px"} !important;
  }

  .custom-margin-x-#{$i}{
    margin-left: #{$i}#{"px"} !important;
    margin-right: #{$i}#{"px"} !important;
  }

  .custom-margin-y-#{$i}{
    margin-top: #{$i}#{"px"} !important;
    margin-bottom: #{$i}#{"px"} !important;
  }

  .custom-padding-#{$i}{
    padding: #{$i}#{"px"} !important;
  }

  .custom-padding-top-#{$i}{
    padding-top: #{$i}#{"px"} !important;
  }

  .custom-padding-right-#{$i}{
    padding-right: #{$i}#{"px"} !important;
  }

  .custom-padding-bottom-#{$i}{
    padding-bottom: #{$i}#{"px"} !important;
  }

  .custom-padding-left-#{$i}{
    padding-left: #{$i}#{"px"} !important;
  }

  .custom-padding-x-#{$i}{
    padding-left: #{$i}#{"px"} !important;
    padding-right: #{$i}#{"px"} !important;
  }

  .custom-padding-y-#{$i}{
    padding-top: #{$i}#{"px"} !important;
    padding-bottom: #{$i}#{"px"} !important;
  }


  .custom-opacity-percentage-#{$i}{
    opacity: #{$i}#{"%"};
  }

  .custom-border-radius-#{$i}{
    border-radius: #{$i}#{"px"};
  }

  .custom-styletheme-table-sticky-header-right-#{$i} {
    position: sticky;
    right: $i + px !important;
    z-index: 101 !important;
    background: #b59f63 !important;
  }

  .custom-styletheme-table-sticky-header-left-#{$i} {
    position: sticky;
    left: $i + px !important;
    z-index: 101 !important;
    // background: #E2DFFF !important;
  }

  .custom-styletheme-table-sticky-body-right-#{$i} {
    position: sticky;
    right: $i + px !important;
    z-index: 100 !important;
    background: linear-gradient(0deg, rgba(86, 85, 168, 0.05), rgba(86, 85, 168, 0.05)), #FFFBFF !important;
  }

  .custom-styletheme-table-sticky-body-right-#{$i}:hover {
    background-color: #eeeef2 !important;
  }
  
  .custom-styletheme-table-sticky-body-left-#{$i} {
    position: sticky;
    left: $i + px !important;
    z-index: 100 !important;
    background: linear-gradient(0deg, rgba(86, 85, 168, 0.05), rgba(86, 85, 168, 0.05)), #FFFBFF !important;

  }


  .custom-skeleton-table {
    margin: 0px 12px 3px 0px;
    padding: 11px;
  }

  .custom-skeleton-table-header{
    background-color: $color-secondary !important 
  }
  


  
  // custom container 
  .custom-container-gap-#{$i} div{
    margin-right: $i + px !important;
  }
  
}